<div
  class="container"
  [ngClass]="type"
>
  @if (title) {
    <div class="icon-title">
      @if (icon) {
        <mat-icon
          class="icon informations"
          [svgIcon]="icon"
          [ngClass]="type"
        ></mat-icon>
      }
      <span>{{ title }}</span>
    </div>
  }
  <div class="row">
    @if (icon && !title) {
      <mat-icon
        class="icon informations"
        [svgIcon]="icon"
        [ngClass]="type"
      ></mat-icon>
    }
    @if (message) {
      <span class="message">
        {{ message }}
      </span>
    }
  </div>
</div>
