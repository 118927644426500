import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'dmc-ng-informations',
  templateUrl: './informations.component.html',
  styleUrls: ['./informations.component.scss'],
  imports: [MatIconModule, CommonModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
})
export class InformationsComponent {
  @Input() icon?: string;
  @Input() message = '';
  @Input() title?: string;
  @Input() type: 'error' | 'warning' | 'info' | 'default-info' = 'warning';
}
